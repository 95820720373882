import axios from 'axios';
import { APIURL, BOOKING_API_URL } from '../../constants/apiConstants';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authAxios from '../../utils/authAxios';


export const getAvailableAppointments = createAsyncThunk('availableAppointments', async (data) => {

    const getUserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let apiKey;
    if(BOOKING_API_URL  == 'https://devapis.heartandpaw.com'){
      apiKey  = "dev-81e5fe7a-3fd8-46d3-aeb0-cb6ed1046004"
    }
    else if(BOOKING_API_URL  == 'https://preprodapis.heartandpaw.com'){
      apiKey  = "dev-81e5fe7a-3fd8-46d3-aeb0-cb6ed1046004"
    }
    else if(BOOKING_API_URL  == 'https://uatapis.heartandpaw.com'){
      apiKey  = "uat-81e5fe7a-3fd8-46d3-aeb0-cb6ed1046004"
    }
    else if(BOOKING_API_URL  == 'https://cloud.heartandpaw.com'){
      apiKey  = "prod-81e5fe7a-3fd8-46d3-aeb0-cb6ed1046004"
    }
    const headers= {'API_KEY': apiKey}


    const endPoint = `${APIURL.AVAILABLE_APPOINTMENTS}?apptType=${data.apptType}&apptTypeId=${data.apptTypeId}&locationId=${data.locationId}&startDate=${data.startDate}&endDate=${data.endDate}&numbenumberOfPets=1&timeZone=${getUserTimezone}`;

    const response = await authAxios.get(endPoint, {headers: headers});
    return response.data;
})


const availableAppointmentsSlice = createSlice({
    name: 'availableAppointments',
    initialState: {
        appointments: [],
        providers: [],
        isLoading: false,
        isError: false,
        isSuccess: false
    },
    reducers: {

        resetAppointments(state) {

            state.appointments = [];
            state.providers = [];
            state.isLoading = false;
            state.isError = false;
            state.isSuccess = false;
        }
    },
    extraReducers: (builder) => {

        builder
            .addCase(getAvailableAppointments.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAvailableAppointments.fulfilled, (state, action) => {
                state.isLoading = false;
                state.appointments = action.payload.appointments;
                state.providers = action.payload.providers;
                state.isSuccess = true;
            })
            .addCase(getAvailableAppointments.rejected, (state) => {
                state.isLoading = false;
                state.isError = false;
            })
    }
});

export const availableAppointmentsAction = availableAppointmentsSlice.actions;
export default availableAppointmentsSlice;





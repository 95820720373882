import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import paths from "./constants/paths";
import PlayPackDetails from "./pages/myHeartAndPaw/MyPlans/PlayPackDetails";
import ViewPlan from "./pages/myHeartAndPaw/MyPlans/ViewPlans/ViewPlan";
import Refer from "./pages/myHeartAndPaw/ReferAFriend/Refer";
import RedirectToMyReferPage from "./pages/RedirectToMyReferPage";

const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));
const WelcomeLayout = React.lazy(() => import("./layout/WelcomeLayout"));
const MyPets = React.lazy(() => import("./pages/myPets/MyPets"));
const Username = React.lazy(() => import("./pages/signUp/index"));
const Welcome = React.lazy(() => import("./pages/Welcome"));
const Login = React.lazy(() => import("./pages/login/index"));
const PetProfile = React.lazy(() =>
  import("./pages/myPets/PetProfile/PetProfile")
);
const BookAppointments = React.lazy(() =>
  import("./pages/bookAppointments/index")
);
const TodaysAppiontment = React.lazy(() =>
  import("./pages/todaysAppointment/index")
);
const Appointments = React.lazy(() => import("./pages/appointments/index"));
const PageNotFound = React.lazy(() => import("./pages/PageNotFound"));
const VerifyOTP = React.lazy(() => import("./pages/login/VerifyOTP"));
const CreateAccount = React.lazy(() => import("./pages/signUp/CreateAccount"));
const BellNotifications = React.lazy(() =>
  import("./pages/myPets/BellIconNotification/Notification")
);
const PetInfo = React.lazy(() => import("./pages/myPets/AddPet/PetInfo"));
const PetType = React.lazy(() => import("./pages/myPets/AddPet/PetType"));
const PetPhoto = React.lazy(() => import("./pages/myPets/AddPet/PetPhoto"));
const AppointmentDetails = React.lazy(() =>
  import("./pages/appointments/AppointmentDetails")
);
const MyHeartAndPaw = React.lazy(() => import("./pages/myHeartAndPaw/index"));
const PetParents = React.lazy(() =>
  import("./pages/myHeartAndPaw/PetParents/PetParents")
);
const MyProfile = React.lazy(() =>
  import("./pages/myHeartAndPaw/MyProfile/MyProfile")
);
const BookNow = React.lazy(() => import("./pages/contact/BookNow"));
const CreditCard = React.lazy(() =>
  import("./pages/myHeartAndPaw/MyCreditCards/CreditCards")
);
const CreditCardForm = React.lazy(() =>
  import("./pages/myHeartAndPaw/MyCreditCards/CreditCardForm")
);

// For contact
const Contact = React.lazy(() => import("./pages/contact/index"));
const ContactLocation = React.lazy(() =>
  import("./pages/contact/ContactLocation")
);
const LocationDetails = React.lazy(() =>
  import("./pages/contact/LocationDetails")
);

// For Invoices
const Invoices = React.lazy(() =>
  import("./pages/myHeartAndPaw/Invoices/index")
);

// For MyPlans
const MyPlans = React.lazy(() => import("./pages/myHeartAndPaw/MyPlans/index"));

// For Locations
const Locations = React.lazy(() =>
  import("./pages/myHeartAndPaw/Locations/index")
);

// For TermsConditions

const TermsConditions = React.lazy(() =>
  import("./pages/myHeartAndPaw/TermsConditions/index")
);
const AboutUs = React.lazy(() => import("./pages/myHeartAndPaw/AboutUs/index"));
const NotificationSettings = React.lazy(() =>
  import("./pages/myHeartAndPaw/NotificationSettings/index")
);
const RescheduleAppointment = React.lazy(() =>
  import("./pages/appointments/RescheduleAppointment/RescheduleAppointment")
);
const VaccinationDetails = React.lazy(() =>
  import("./pages/myPets/PetProfile/VaccinationDetails")
);
const LabDetails = React.lazy(() =>
  import("./pages/myPets/PetProfile/LabDetails")
);
const EditPetPhoto = React.lazy(() =>
  import("./pages/myPets/PetProfile/EditPetProfile/EditPetPhoto")
);
const AddImages = React.lazy(() =>
  import("./pages/myPets/PetProfile/AddImages/AddImages")
);
const AddPetParent = React.lazy(() =>
  import("./pages/myHeartAndPaw/PetParents/AddPetParent")
);

// For  reminder redirection
const RedirectToProfile = React.lazy(() => import("./pages/RedirectToProfile"));
// For forword booking redirection
const AutoRedirection = React.lazy(() => import("./pages/AutoRedirection"));
// Forward Booking Notification page
const ForwardBookingNotification = React.lazy(() =>
  import("./pages/ForwardBookingNotification")
);

// For Switch the appointments status [Planned, Confirmed, Canceled, . . . ]
const SwitchAppointmentStatus = React.lazy(() =>
  import("./pages/SwitchAppointmentStatus")
);
const SwitchAppointmentStatusNotification = React.lazy(() =>
  import("./pages/SwitchAppointmentStatusNotification/index")
);

const SelectOTPOptions = React.lazy(() =>
  import("./pages/login/SelectOTPOptions")
);

const RedirectToMyCreditCardsPage = React.lazy(() =>
  import("./pages/RedirectToMyCreditCardsPage")
);
const PlayPackges = React.lazy(() => import("./pages/playPackages/index"));
const PackageSummary = React.lazy(() =>
  import("./pages/playPackages/PackageSummary")
);

const TalkToVet = React.lazy(() => import("./pages/talkToVet/index"));
const Pharmacy = React.lazy(() => import("./pages/Pharmacy/Pharmacy"));

const routes = (authData,location) => {
  const { isSignedIn } = authData;
  // console.log("authData", authData.user.location);

  return [
    {
      path:paths.HOME.pathName,
      element: isSignedIn ? (
        <DefaultLayout authData={authData} />
      ) : (
        <Navigate to={paths.WELCOME.pathName} state={location?.pathname}/>
      ),
      children: [
        { path: paths.HOME.pathName, element: <MyPets /> },
        {
          path: paths.PET_PROFILE.pathName + "/:petId",
          element: <PetProfile />,
        },
        {
          path: paths.BOOK_APPOINTMENTS.pathName,
          element: <BookAppointments />,
        },
        { path: paths.APPOINTMENTS.pathName, element: <Appointments /> },
        {
          path: paths.BELL_NOTIFICATIONS.pathName,
          element: <BellNotifications />,
        },
        { path: paths.BOOK_NOW.pathName, element: <BookNow /> },
        {
          path: paths.TODAYS_APPOINTMENT.pathName,
          element: <TodaysAppiontment />,
        },
        { path: "/appointments/:appointmentStatus", element: <Appointments /> },
        { path: "/appointment/:id", element: <AppointmentDetails /> },
        {
          path: "/appointment/:appointmentStatus/:id",
          element: <AppointmentDetails />,
        },
        {
          path: "/appointment/reschedule/:id",
          element: <RescheduleAppointment />,
        },
        { path: "/add-pet/info", element: <PetInfo /> },
        { path: "/add-pet/type", element: <PetType /> },
        { path: "/add-pet/photo", element: <PetPhoto /> },
        { path: "/edit-pet-profile/:petId", element: <EditPetPhoto /> },
        { path: "/add-images/:petId", element: <AddImages /> },
        { path: "/contact", element: <Contact /> },
        { path: "/contact/location", element: <ContactLocation /> },
        {
          path: "/contact/location/details/:locationId",
          element: <LocationDetails />,
        },
        { path: "/my-hp", element: <MyHeartAndPaw /> },
        { path: "/pet-parents", element: <PetParents /> },
        { path: "/pet-parents/add", element: <AddPetParent /> },
        { path: "/my-profile", element: <MyProfile /> },
        {
          path: "/vaccination-details/:vaccinationId",
          element: <VaccinationDetails />,
        },
        { path: "/lab-details/:labId/:patientId", element: <LabDetails /> },
        { path: "/setting", element: "Setting is loading. . ." },
        { path: "/my-plans/:activePlan", element: <MyPlans /> },
        { path: "/my-plans/plan-details/:myPlanId", element: <ViewPlan /> },
        { path: "/location", element: <Locations /> },
        { path: "/invoices", element: <Invoices /> },
        { path: "/terms-and-conditions", element: <TermsConditions /> },
        { path: "/about-us", element: <AboutUs /> },
        { path: "/notification-settings", element: <NotificationSettings /> },
        { path: "/my-credit-cards", element: <CreditCard /> },
        { path: "/my-credit-cards/add", element: <CreditCardForm /> },
        { path: "/talk-to-vet", element: <TalkToVet /> },
        { path: "/pharmacy", element: <Pharmacy /> },
        {
          path: "/forward-booking-notification",
          element: <ForwardBookingNotification />,
        },
        {
          path: "/auto-confirmation-notification",
          element: <SwitchAppointmentStatusNotification />,
        },
        { path: "/buy-play-packages", element: <PlayPackges /> },
        { path: "/package-summary", element: <PackageSummary /> },
        { path: "/play-pack-details/:packId", element: <PlayPackDetails /> },
        { path: "/logout", element: "My H + P" },
        { path: "/refer-friend", element: <Refer /> },
      ],
    },
    {
      path: paths.WELCOME.pathName,
      element: !isSignedIn ? (
        <WelcomeLayout />
      ) : (
        <Navigate to={ location?.state && isSignedIn ? location?.state : paths.HOME.pathName} state={location?.pathname}/>
      ),
      children: [
        { path: paths.WELCOME.pathName, element: <Welcome /> },
        { path: paths.LOGIN.pathName, element: <Login /> },
        { path: paths.VERIFY_OTP.pathName, element: <VerifyOTP /> },
        { path: "select-otp-options", element: <SelectOTPOptions /> },
        { path: "sign-up", element: <Username /> },
        { path: "create-account", element: <CreateAccount /> },
      ],
    },
    { path: "/redirect-profile", element: <RedirectToProfile /> },
    { path: "/auto-redirection", element: <AutoRedirection /> },
    { path: "/auto-confirmation", element: <SwitchAppointmentStatus /> },
    { path: "/redirect-credit-card", element: <RedirectToMyCreditCardsPage /> },
    { path: "/redirect-refer-friend", element: <RedirectToMyReferPage /> },

    { path: "*", element: <PageNotFound /> },
  ];
};

export default routes;
